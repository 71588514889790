import React from "react";
import ShowcaseItem, { ShowcaseItemType } from "@/components/showcase-item";
import ShowcaseIntro from "@/components/showcase-intro";

type ShowcaseType = {
  showcaseItems: ShowcaseItemType[];
};

const Showcase: React.FC<ShowcaseType> = ({ showcaseItems }) => {
  return (
    <section
      className="m-showcase__container dark"
      data-scroll-section
      id="showcase-section-marker"
    >
      <div className="m-showcase__heading container">
        <h2
          className="heading-large underline"
          data-scroll
          data-scroll-target="#showcase-section-marker"
        >
          Showcase
        </h2>
      </div>
      <ShowcaseIntro />
      {showcaseItems.map((item, i) => (
        <ShowcaseItem
          title={item.title}
          description={item.description}
          image={item.image}
          link={item.link}
          video={item.video}
          key={i}
          index={i}
        />
      ))}
    </section>
  );
};

export default Showcase;
