import React from "react";

export type ServiceItem = {
  name: string;
};

type ServiceListTypes = {
  heading?: string;
  services: ServiceItem[];
};

const ServiceList: React.FC<ServiceListTypes> = ({ heading, services }) => (
  <div
    className="c-service-list"
    data-scroll
    data-scroll-speed="1.2"
    data-scroll-target="#services-marker"
  >
    <>
      {heading && (
        <h2
          className="c-service-list__heading heading-small uppercase underline"
          data-scroll
          data-scroll-target="#services-marker"
          data-scroll-offset="40%"
        >
          {heading}
        </h2>
      )}
      {services?.[0] && (
        <ul className="c-service-list__items">
          {services.map((service, i) => (
            <li className="c-service-list__item body-regular" key={i}>
              {service.name}
            </li>
          ))}
        </ul>
      )}
    </>
  </div>
);

export default ServiceList;
