import React from "react";

type IntroProps = {
  sectionHeading?: string;
  text: string;
};

const Intro: React.FC<IntroProps> = ({ sectionHeading, text }) => (
  <div
    className="e-intro"
    data-scroll
    data-scroll-speed="1.1"
    data-scroll-target="#services-marker"
  >
    {sectionHeading && (
      <h2 className="e-intro__heading heading-showcase">{sectionHeading}</h2>
    )}
    <p className="e-intro__text">{text}</p>
  </div>
);

export default Intro;
