import React from "react";
import Intro from "@/elements/intro";
import ServiceList, { ServiceItem } from "@/components/service-list";

type ServicesType = {
  sectionHeading?: string;
  intro: string;
  heading: string;
  services: ServiceItem[];
};

const Services: React.FC<ServicesType> = ({
  sectionHeading,
  intro,
  heading,
  services,
}) => {
  return (
    <section
      className="c-services__wrapper light"
      data-scroll-section
      id="services-marker"
    >
      <div
        className="c-services container underline"
        data-scroll
        data-scroll-offset="75%"
        data-scroll-target="#services-marker"
      >
        <Intro sectionHeading={sectionHeading} text={intro} />
        <ServiceList heading={heading} services={services} />
      </div>
    </section>
  );
};

export default Services;
