import equiptec from "@/vid/equiptec.mp4";
import howlingMoon from "@/vid/howling-moon.mp4";
import nsw from "@/vid/nsw.mp4";
import merch from "@/vid/merch.mp4";
import nextGen from "@/vid/next-gen.mp4";
import hitachi from "@/vid/hitachi.mp4";

const staticVideo = (video: string) => {
  let newVideo = video;
  const videoObject = {
    howlingMoon,
    nsw,
    equiptec,
    merch,
    hitachi,
    nextGen,
  };
  newVideo = videoObject[video] ? videoObject[video] : video;
  return newVideo;
};

export default staticVideo;
